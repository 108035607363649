import { Inter } from "@next/font/google";
import type { AppProps } from "next/app";
import "../styles/globals.css";

const montserrat = Inter({ subsets: ["latin"], variable: "--font-custom" });

export default function App({ Component, pageProps }: AppProps) {
  return (
    <main className={`${montserrat.variable} font-sans bg-[#000E1F]`}>
      <Component {...pageProps} />
    </main>
  );
}
